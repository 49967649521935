import { SvgIcon } from "@mui/material";

const SchoolIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 64 64" {...props}>
      <path
        d="M32 4L2 20l30 16 30-16L32 4z"
        fill="currentColor"
      />
      <path
        d="M32 36v24l-10-5V31l10 5zM42 31v24l-10 5V36l10-5z"
        fill="currentColor"
      />
      <path
        d="M10 28l2 10 20 10 20-10 2-10-22 12-22-12z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default SchoolIcon;
