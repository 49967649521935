import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import useSettings from "hooks/useSettings";
import LoadingScreen from "components/LoadingScreen";
import LayoutV3 from "layouts/layout-v3/DashboardLayout";
import NotificationMessages from "pages/Apps/AppConfiguration/Notification/NotificationMessages";
// import ListOfNotification from "pages/Apps/AppConfiguration/Notification/ListOfNotifications";
import UserList from "pages/Apps/UserManagement/UserList";
import AddUserPage from "pages/Apps/UserManagement/AddUser";
import CategoryList from "pages/Apps/Category/CategoryList";
import BrandList from "pages/Apps/Category/BrandList";
import SubcategoryList from "pages/Apps/Category/SubCategoryList";
// import ListOfNotification from "pages/Apps/AppConfiguration/Notification/ListOfNotifications";


const Loadable = Component => props => {
  return <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>;
}; // dashboards


const AppsList = Loadable(lazy(() => import("./pages/Apps/Appslist")));// 404/Error page
const SchoolCreate = Loadable(lazy(() => import("./pages/School/CreateSchool")));// 404/Error page
const SchoolList=Loadable(lazy(() => import("./pages/Apps/AppConfiguration/Schools/SchoolList")));// 404/Error page
const AppConfigTabs = Loadable(lazy(() => import("./pages/Apps/AppConfiguration/AppConfigTabs")))
const CreateProduct = Loadable(lazy(() => import("./pages/Apps/AppConfiguration/Products/CreateProduct")));
const CreateSchool = Loadable(lazy(() => import("./pages/Apps/AppConfiguration/Schools/CreateSchool")));
const ProductImport = Loadable(lazy(() => import("./pages/Apps/AppConfiguration/Products/ProductImport")));
const Merchant = Loadable(lazy(() => import("./pages/Apps/Merchannts/MerchantTabs")));
const Order = Loadable(lazy(() => import("./pages/Apps/Orders/OrderTab")));
const EditOrder = Loadable(lazy(() => import("./pages/Apps/Orders/OrderEdit1")));
const CreateMerchant = Loadable(lazy(() => import("./pages/Apps/Merchannts/CreateMerchants")));
const CreateHomeSections = Loadable(lazy(() => import("./pages/Apps/AppConfiguration/HomeSection/CreateHomeSection")));
const CreatePage = Loadable(lazy(() => import("./pages/Apps/AppConfiguration/Pages/CreatePage")));
const Notification = Loadable(lazy(() => import("./pages/Apps/AppConfiguration/Notification/SendNotification")));
const NotificationTokenList = Loadable(lazy(() => import("./pages/Apps/AppConfiguration/Notification/NotificationTokenList")));
const Product = Loadable(lazy(() => import("./pages/Apps/AppConfiguration/Products/ProductsList")));
const GeneralDeatils=Loadable(lazy(() => import("./pages/Apps/AppConfiguration/GeneralDeatils/GeneralDetails")));
const ContactDetails=Loadable(lazy(() => import("./pages/Apps/AppConfiguration/ContactPage/ContactPage")));
const HomeSectionList=Loadable(lazy(() => import("./pages/Apps/AppConfiguration/HomeSection/HomeSectionList")));
const Menu=Loadable(lazy(() => import("./pages/Apps/AppConfiguration/Menus/MenuCreate")));
const PageList=Loadable(lazy(() => import("./pages/Apps/AppConfiguration/Pages/PageList")));
const CancelReason=Loadable(lazy(() => import("./pages/Apps/AppConfiguration/Reasons/CancelReasons")));
const ReturnReason=Loadable(lazy(() => import("./pages/Apps/AppConfiguration/Reasons/ReturnReasons")));
const ListOfNotification=Loadable(lazy(() => import("./pages/Apps/AppConfiguration/Notification/ListOfNotifications")));
const OrderCharges=Loadable(lazy(() => import("./pages/Apps/AppConfiguration/OrderCharges/OrderCharges")));
const PaymentGateway=Loadable(lazy(() => import("./pages/Apps/AppConfiguration/Payment/Payment")));
const AppSelection=Loadable(lazy(() => import("./pages/Apps/AppSelection/AppSelection")));
const GlobalCatalogList=Loadable(lazy(() => import("./pages/Apps/AppConfiguration/Globalcatalog/GlobalCatalogList")));
const Error = Loadable(lazy(() => import("./pages/404")));

const routes = () => {
  return [{
    path: "/",
    element: <LayoutV3 />,
    children: pageRoutes
  }, {
    path: "*",
    element: <Error />
  }];
};



const pageRoutes = [
  // {
  //   path: "/",
  //   element: <AppsList />
  // },
  {
    path: "dashboard/import-product",
    element: <ProductImport/>
  },
  {
    path: "dashboard/app-selection",
    element: <AppSelection />
  },
  {
    path: "dashboard/school",
    element: <SchoolList />
  },
  {
    path: "dashboard/add-school",
    element: <SchoolCreate />
  },
  {
    path:"dashboard/generaldetails",
    element:<AppsList/>
  },
  {
    path:"dashboard/contactdetails",
    element:<ContactDetails/>
  },
  {
    path: "dashboard/app-config",
    element: <AppConfigTabs />
  },
  {
    path: "dashboard/product-create",
    element: <CreateProduct/>
  },
  {
    path:"dashboard/homesections",
    element:<HomeSectionList/>
  },
  {
    path:"dashboard/menu",
    element:<Menu/>
  },
  {
   path:"dashboard/page",
   element:<PageList/>
  },
  {
    path:"dashboard/cancelreason",
    element:<CancelReason/>
  },
  {
    path:"dashboard/returnreason",
    element:<ReturnReason/>
  },
  {
    path:"dashboard/notification",
    element:<ListOfNotification/>
  },
  {
    path:"dashboard/charges",
    element:<OrderCharges/>
  },
  {
    path:"dashboard/payment",
    element:<PaymentGateway/>
  },
  {
    path: "dashboard/school-create",
    element: <CreateSchool/>
  },
  // {
  //   path: "dashboard/import-product",
  //   element: <ProductImport/>
  // },
  {
    path: "dashboard/product",
    element: <Product/>
  },
  {
    path: "dashboard/merchant",
    element: <Merchant/>
  },
  {
    path: "dashboard/order",
    element: <Order/>
  },
  {
    path: "dashboard/create-merchant",
    element: <CreateMerchant/>
  },
  {
    path: "dashboard/edit-order",
    element: <EditOrder/>
  },
  {
    path: "dashboard/create-homesections",
    element: <CreateHomeSections/>
  },
  {
    path: "dashboard/create-page",
    element: <CreatePage/>
  },
  {
    path: "dashboard/notification-send",
    element: <Notification/>
  },
  {
    path: "dashboard/notification-token-list",
    element: <NotificationTokenList/>
  },
  {
    path: "dashboard/notification-create",
    element: <NotificationMessages/>
  },
  {
    path:"dashboard/import-global-catalog",
    element:<GlobalCatalogList/>
  },
  // {
  //   path: "dashboard/notification-list",
  //   element: <ListOfNotification/>
  // },
  {
    path: "dashboard/user-list",
    element: <UserList/>
  },
  {
    path: "dashboard/add-user",
    element: <AddUserPage/>
  },
  {
    path: "dashboard/category-list",
    element: <CategoryList/>
  }, {
    path: "dashboard/subcategory-list",
    element: <SubcategoryList/>
  },
  {
    path: "dashboard/brand-list",
    element: <BrandList/>
  },

];

export default routes;