import React,{useState} from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  ButtonBase,
  Divider,
  Stack,
  styled,
} from "@mui/material";
import AppCheckBox from "components/AppCheckBox";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import { Small } from "components/Typography";
import Facebook from "icons/Facebook";
import GoogleIcon from "icons/GoogleIcon";
import Twitter from "icons/Twitter";
import AuthenticationLayout from "page-sections/authentication/AuthenticationLayout";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { login } from "Store/Slices/Authslice";
import axios from "axios";
import AppSelection from "pages/Apps/AppSelection/AppSelection";
const StyledButton = styled(ButtonBase)(({ theme }) => ({
  // Your existing styles...
}));

const Login = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { control, handleSubmit, formState: { errors }, } = useForm();

  const onSubmit = async (data) => {
    try {
      //http://localhost:5001/admin/api/v1/login
    //  https://omniposibleapi-bz4mvd3zea-el.a.run.app/admin/api/v1/login"
    
      const response = await axios.post("https://backend.booksmart.co.in/api/user/login", data);
      if (response.data.status=="Success") {
        let data = {
          auth: true,
          user: {...response.data.data},
          token : response.data.accessToken
        };
         
        toast.success("Login Successful");
        //console.log(response.data)
      dispatch(login(data));
       // setIsModalOpen(true)

    
        //  dispatch(login(response.data));
          navigate("/dashboard/product", { state: { data: response.data, } });
        
 
      } else {
        toast.error("Login Failed");
      }
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

 
  return (<>
    <AuthenticationLayout
      route="/register"
    // description="New Here?"
    // title=""
    // routeName="Create an account"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2} mt={5}>
          <Controller
            name="email"
            control={control}
            defaultValue="gulsanvarma2589@gmail.com"
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address",
              },
            }}
            render={({ field }) => (
              <AppTextField
                fullWidth
                label="Login Id"
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                sx={{
                  borderColor: errors.email ? 'red' : undefined,
                }}
                {...field}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            defaultValue="test"
            rules={{
              required: "Password is required",
              // minLength: {
              //   value: 8,
              //   message: "Password must be at least 8 characters",
              // },
            }}
            render={({ field }) => (
              <AppTextField
                fullWidth
                label="Password"
                type="password"
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
                sx={{
                  borderColor: errors.password ? 'red' : undefined,
                }}
                {...field}
              />
            )}
          />

          {/* <FlexBetween>
            <FlexBox alignItems="center" gap={1}>
              <AppCheckBox defaultChecked />
              <Small fontSize={12}>Remember me</Small>
            </FlexBox>

            <Button disableRipple sx={{ color: "error.main", mb: 2 }}>
              Forget Password
            </Button>
          </FlexBetween> */}

          <Button type="submit" variant="contained">
            Sign In
          </Button>
        </Stack>
      </form>
<div></div>
      {/* <Divider sx={{ marginTop: 4 }}>
        <Small color="text.disabled" px={1}>
          OR
        </Small>
      </Divider> */}

      {/* <Stack direction="row" justifyContent="space-between" flexWrap="wrap" my={3}>
        <StyledButton>
          <GoogleIcon sx={{
          marginRight: 1,
          fontSize: "1.2rem"
        }} />
          Signin with Google
        </StyledButton> 

        <StyledButton>
          <Facebook sx={{
          color: "#2475EF",
          marginRight: 1,
          fontSize: "1.2rem"
        }} />
          Signin with Facebook
        </StyledButton>

         <StyledButton>
          <Twitter sx={{
          color: "#45ABF7",
          marginRight: 1,
          fontSize: "1.2rem"
        }} />
          Signin with Twitter
        </StyledButton>
      </Stack> */}
      <Toaster position="top-right" />
    </AuthenticationLayout>
     {/* <AppSelection openmodal={isModalOpen} onClose={(data)=>setIsModalOpen(data)}/> */}
    </>
  );
};

export default Login;
